import { inject, Injectable } from '@angular/core';
import {
  Firestore,
  doc,
  updateDoc,
  getDoc,
  DocumentData,
} from '@angular/fire/firestore';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from '@angular/fire/storage';
import { Auth, updateEmail, updateProfile } from '@angular/fire/auth';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  firestore = inject(Firestore);
  storage = getStorage();
  auth = inject(Auth);
  authService = inject(AuthService);

  async updateProfilePicture(file: File) {
    const storageRef = ref(
      this.storage,
      `${this.authService.userSignal()?.uid}-files/${file.name}`
    );
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    if (!this.auth.currentUser) return;
    updateProfile(this.auth.currentUser, {
      photoURL: downloadURL,
    });
  }

  updateProfileDisplayName(displayName: string) {
    if (!this.auth.currentUser) return;

    if (displayName) {
      updateProfile(this.auth.currentUser, {
        displayName: displayName,
      });
    } else {
      return;
    }
  }

  async updateProfilEmail(email: string) {
    if (!this.auth.currentUser) return;

    if (email) {
      updateEmail(this.auth.currentUser, email);
    } else {
      return;
    }
  }

  updateProfileDescription(description: string) {
    if (!this.auth.currentUser) return;

    updateDoc(doc(this.firestore, 'Users', this.auth.currentUser.uid), {
      description: description,
    });
  }

  async getUserData(): Promise<DocumentData | undefined> {
    if (!this.auth.currentUser) {
      return undefined;
    }

    try {
      const docRef = doc(this.firestore, 'Users', this.auth.currentUser.uid);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        return docSnapshot.data();
      } else {
        return undefined; // Return a default value when the document does not exist
      }
    } catch (error) {
      console.error('Error getting document:', error);
      return undefined; // Return a default value when an error occurs
    }
  }
}
